<script setup>
import LabelledField from '../utils/LabelledField.vue'
import { computed, ref } from 'vue'

const props = defineProps({
    value: {
        type: String,
        default: null
    },
    label: {
        type: String,
        default: null
    },
    required: {
        type: Boolean,
        default: true
    },
    showPasswordLabel: {
        type: String,
        default: 'Show Password'
    }
})

const emit = defineEmits(['input'])

const innerValue = computed({
    get() {
        return props.value
    },
    set(newVal) {
        emit('input', newVal)
    }
})

const showPassword = ref(false)

const fieldType = computed(() => {
    if (showPassword.value) {
        return 'text'
    }
    return 'password'
})
</script>

<template>
    <div>
        <LabelledField :label="label" :required="required">
            <v-text-field class="text-field-background" outlined dense required v-model="innerValue" hide-details="auto"
                v-bind="$attrs" :type="fieldType"></v-text-field>
        </LabelledField>

        <v-checkbox dense hide-details="auto" v-model="showPassword">
            <template #label>
                <span class="text-caption text-md-body-2">{{ showPasswordLabel }}</span>
            </template>
        </v-checkbox>
    </div>
</template>