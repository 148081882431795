<script setup>
import LoadingButton from "@/components/utils/LoadingButton.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import { useOtp } from "./business_logic";
import { requestStatus } from "@/utils/constants";
import { getInternationalNumber } from "@/utils/phone_number";
import { stringNotEmpty } from "@/utils/functions";
import { fieldRequired } from "@/utils/input_validation.js";
import { countyIsKilifi, countyIsKirinyaga } from "@/utils/vue_helpers";
import { ref, watchEffect, computed, onMounted } from "vue";
import LoadingArea from "@/components/utils/LoadingArea.vue";

const props = defineProps({
  phoneNumber: {
    type: [String, Number],
    default: null,
  },
  countryCode: {
    type: [String],
    default: null,
  },
  emailAddress: {
    type: [String],
    default: null,
  },
  verifying: {
    type: Boolean,
    default: false,
  },
  idNumber: {
    type: String,
    default: null,
  },
  usingIdNumber: {
    type: Boolean,
    default: false,
  },
  otpInvalid: {
    type: Boolean,
    default: null,
  },
  resetOtpStatusToInitialFn: {
    type: Function,
    default: null,
  },
  sendOtpImmediately: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  props.resetOtpStatusToInitialFn();
  if (props.sendOtpImmediately) {
    onSendOtpClicked();
  }
});

const { otpData, sendOtp, resetOtpToSendStage } = useOtp({
  sendOtpImmediately: !props.sendOtpImmediately,
});

function onResetOtpToSendStageClicked() {
  props.resetOtpStatusToInitialFn();
  resetOtpToSendStage();
  if (props.sendOtpImmediately) {
    onSendOtpClicked();
  }
}

const emit = defineEmits(["verifyOtpClicked"]);

const sendingMethod = {
  PHONE_NUMBER: 0,
  EMAIL: 1,
};
const selectedButtonClass = "secondary white--text";
const otpSendingMethodModel = ref(
  countyIsKilifi.value ? sendingMethod.EMAIL : sendingMethod.PHONE_NUMBER
);
const sendToText = ref("");
const checkForOtpText = ref("");
const form = ref(null);

const sendingViaPhone = computed(() => {
  return otpSendingMethodModel.value === sendingMethod.PHONE_NUMBER;
});

const sendingViaEmail = computed(() => {
  return otpSendingMethodModel.value === sendingMethod.EMAIL;
});

const internationalNumber = computed(() => {
  if (props.usingIdNumber === true) {
    return props.phoneNumber;
  }

  try {
    return getInternationalNumber(props.phoneNumber, props.countryCode);
  } catch (error) {
    return "";
  }
});

const phoneNumberValid = computed(() => {
  return stringNotEmpty(internationalNumber.value);
});

const showPhoneNumberInvalid = computed(() => {
  const phoneNumberInvalid = !phoneNumberValid.value;
  return phoneNumberInvalid && sendingViaPhone.value && !props.usingIdNumber;
});

const hasEmail = computed(() => {
  return stringNotEmpty(props.emailAddress);
});

watchEffect(() => {
  const phoneNumberText = `phone number: ${internationalNumber.value}`;
  const emailText = `email address: ${props.emailAddress}`;
  if (countyIsKirinyaga.value) {
    const kiriEmailText = `<span class="font-weight-bold">${emailText}</span>`;
    const kiriSendToEmailText = hasEmail.value ? `and ${kiriEmailText}` : "";
    const kiriCheckOtpEmailText = hasEmail.value ? `or ${kiriEmailText}` : "";
    sendToText.value = `<span class="font-weight-bold">${phoneNumberText}</span> ${kiriSendToEmailText}`;
    checkForOtpText.value = `<span class="font-weight-bold">${phoneNumberText}</span> ${kiriCheckOtpEmailText}`;
  } else {
    if (otpData.shouldSendOtpViaPhone === true) {
      otpSendingMethodModel.value = sendingMethod.PHONE_NUMBER;
      sendToText.value = phoneNumberText;
      checkForOtpText.value = phoneNumberText;
    } else {
      otpSendingMethodModel.value = sendingMethod.EMAIL;
      sendToText.value = emailText;
      checkForOtpText.value = emailText;
    }
  }
});

const sendingOtp = computed(() => {
  if (otpData.sendOtpRequestStatus === requestStatus.SENDING) {
    return true;
  }
  return false;
});

const resendOtpTimeoutIsActive = computed(() => {
  if (otpData.resendOtpTimeout > 0) {
    return true;
  }
  return false;
});

const resendButtonDisabled = computed(() => {
  return resendOtpTimeoutIsActive.value || props.verifying;
});

const showSelectSendingMethod = computed(() => {
  return !otpData.otpSent && !countyIsKirinyaga.value;
});

async function onSendOtpClicked() {
  try {
    sendOtp({
      phoneNumber: props.phoneNumber,
      email: props.emailAddress,
      idNumber: props.idNumber,
      sendUsingAllMethods: countyIsKirinyaga.value,
    });
  } catch (error) {}
}

function onVerifyOtpClicked() {
  const formValid = form.value.validate();
  if (formValid) {
    emit("verifyOtpClicked", otpData.otpModel);
  }
}
</script>

<template>
  <div>
    <v-container
      fluid
      :class="{ 'pa-0': true, 'text-body-2': $mobileBreakpoint }"
    >
      <!-- How to send OTP -->
      <v-row v-if="showSelectSendingMethod">
        <v-col cols="12">
          <div>
            Please select how you would like to receive your Verification Code
          </div>
        </v-col>
        <v-col cols="12">
          <v-btn-toggle dense v-model="otpSendingMethodModel">
            <v-btn
              :class="{
                'text-none': true,
                [selectedButtonClass]: sendingViaPhone,
              }"
              @click="otpData.shouldSendOtpViaPhone = true"
            >
              Phone
            </v-btn>
            <v-btn
              :class="{
                'text-none': true,
                [selectedButtonClass]: sendingViaEmail,
              }"
              @click="otpData.shouldSendOtpViaPhone = false"
            >
              Email
            </v-btn>
          </v-btn-toggle>

          <v-divider class="mt-4" />
        </v-col>
      </v-row>

      <template v-if="showPhoneNumberInvalid">
        <v-row>
          <v-col cols="12">
            The Verification Code cannot be sent to your phone number as your
            phone number is invalid
          </v-col>
        </v-row>
      </template>

      <template v-else>
        <v-row no-gutters>
          <v-col cols="12">
            <div class="text-center text-md-left">
              <template v-if="showSelectSendingMethod">
                <div class="mt-2">
                  Your Verification Code will be sent to your
                  <span v-html="sendToText"></span>
                </div>
              </template>
              <template v-else>
                Please check your <span v-html="checkForOtpText"></span> for
                your Verification Code and enter it below.
              </template>
            </div>
          </v-col>
        </v-row>

        <v-row
          v-if="showSelectSendingMethod && !otpData.otpSent"
          justify="center"
          justify-md="start"
        >
          <v-col cols="auto" md="6">
            <LoadingButton
              rounded
              block
              :loading="sendingOtp"
              color="secondary"
              @click="onSendOtpClicked"
            >
              Send Verification Code

              <template #loadingText> Sending Verification Code </template>
            </LoadingButton>
          </v-col>
        </v-row>

        <template v-if="!showSelectSendingMethod || otpData.otpSent === true">
          <v-row>
            <v-col cols="12" md="6">
              <v-form @submit.prevent="onVerifyOtpClicked" ref="form">
                <LabelledField label="Verification Code" required>
                  <v-text-field
                    class="text-field-background"
                    outlined
                    dense
                    required
                    hide-details="auto"
                    v-model="otpData.otpModel"
                    :rules="[fieldRequired]"
                  ></v-text-field>
                </LabelledField>
              </v-form>
            </v-col>
          </v-row>

          <v-row justify="center" justify-md="start">
            <v-col cols="auto" md="4">
              <LoadingButton
                rounded
                block
                :class="{
                  'button-background-secondary-1 white--text': !props.verifying,
                }"
                :loading="props.verifying"
                @click="onVerifyOtpClicked"
              >
                Verify
                <template #loadingText> Verifying </template>
              </LoadingButton>
            </v-col>
          </v-row>

          <v-row v-if="props.otpInvalid">
            <v-col cols="12">
              <div class="error--text text-body-2 text-center text-md-left">
                The Verification Code is invalid. Please try again.
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-divider />
              <div class="mt-6 text-center text-md-left">
                Didn't receive your Verification Code?
                <template v-if="resendOtpTimeoutIsActive">
                  Please wait for
                  <span class="font-weight-bold text-h6 primary--text">{{
                    otpData.resendOtpTimeout
                  }}</span>
                  seconds to resend the code.
                </template>
                <template v-else>
                  Click the button below to resend the code.
                </template>
              </div>
            </v-col>
          </v-row>

          <v-row justify="center" justify-md="start">
            <v-col cols="auto">
              <v-btn
                :disabled="resendButtonDisabled"
                rounded
                block
                outlined
                :class="[
                  'text-none',
                  resendButtonDisabled ? '' : 'primary lighten-2 white--text',
                ]"
                @click="onResetOtpToSendStageClicked"
              >
                <span :class="{ 'white--text': !resendButtonDisabled }">
                  Resend Verification Code
                </span>
              </v-btn>
              <!-- <LoadingButton
                :disabled="resendButtonDisabled"
                rounded
                block
                outlined
                :class="[
                  'text-none',
                  resendButtonDisabled ? '' : 'primary lighten-2 white--text',
                ]"
                @click="onResetOtpToSendStageClicked"
              >
                <span :class="{ 'white--text': !resendButtonDisabled }">
                  Resend Verification Code
                </span>
              </LoadingButton> -->
            </v-col>
          </v-row>

          <template v-if="countyIsKirinyaga">
            <v-row class="mt-4 mt-md-8">
              <v-col
                cols="auto"
                class="text-body-2 font-italic text-center text-md-left"
              >
                Ensure this Safaricom number can receive <br />
                promotional SMS by dialing (*456*9*5#)
              </v-col>
            </v-row>

            <v-row v-if="$scopedSlots.additionalResendOtp" dense>
              <v-col cols="auto" class="text-body-2 font-italic">
                <slot
                  name="additionalResendOtp"
                  :resetOtpToSendStage="resetOtpToSendStage"
                />
              </v-col>
            </v-row>
          </template>
        </template>
      </template>
    </v-container>
  </div>
</template>
