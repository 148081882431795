import { reactive, onMounted, ref, computed } from "vue";
import apiCall from "@/utils/api";
import { requestStatus, INVALID_OTP, otpStatus } from "@/utils/constants";
import { isOtpInvalid } from "@/utils/functions";
import { getPhoneNumberWithCallingCode } from "@/utils/phone_number";
import { countyIsKirinyaga } from "@/utils/vue_helpers";

export function useOtp({ sendOtpImmediately = false }) {
  const resendOtpTimeoutInSeconds = 90;

  const otpData = reactive({
    shouldSendOtpViaPhone: countyIsKirinyaga ? false : true,
    sendOtpRequestStatus: requestStatus.NOT_SENT,
    validatingOtpRequestStatus: requestStatus.NOT_SENT,
    otpSent: false,
    resendOtpTimeout: 0,
    otpModel: "",
  });

  let resendOtpTimeoutId = null;

  function updateResendOtpTimeout() {
    if (otpData.resendOtpTimeout > 0) {
      otpData.resendOtpTimeout -= 1;
      resendOtpTimeoutId = setTimeout(() => {
        updateResendOtpTimeout();
      }, 1000);
    }
  }

  function resetOtpToSendStage() {
    clearTimeout(resendOtpTimeoutId);
    otpData.otpModel = "";
    otpData.otpSent = false;
  }

  function getSendOtpRequestDataAndUrl({
    phoneNumber = null,
    email = null,
    idNumber = null,
    sendUsingAllMethods = false,
  }) {
    let requestData;
    let url;
    const sendUsingIdNumber = idNumber !== null;
    if (sendUsingIdNumber) {
      requestData = {
        id_number: idNumber,
        sending_method: null,
      };
      let sendingMethod;

      if (sendUsingAllMethods) {
        sendingMethod = "all";
      } else {
        if (otpData.shouldSendOtpViaPhone) {
          sendingMethod = "phone";
        } else {
          sendingMethod = "email";
        }
      }
      requestData.sending_method = sendingMethod;
      url = "/api/client/send-otp/existing-user";
    } else {
      requestData = {
        phone_number: null,
        email: null,
      };
      const shouldSendOtpViaPhone =
        otpData.shouldSendOtpViaPhone || sendUsingAllMethods;
      const shouldSendOtpViaEmail =
        !otpData.shouldSendOtpViaPhone || sendUsingAllMethods;
      if (shouldSendOtpViaPhone) {
        const formattedPhoneNumber = getPhoneNumberWithCallingCode(
          phoneNumber,
          "KE"
        );
        requestData.phone_number = formattedPhoneNumber;
      }
      if (shouldSendOtpViaEmail) {
        requestData.email = email;
      }
      url = "/api/client/send-otp/new-user";
    }

    return { requestData, url };
  }

  async function sendOtp({
    phoneNumber = null,
    email = null,
    sendUsingAllMethods = null,
    idNumber = null,
  }) {
    const { requestData, url } = getSendOtpRequestDataAndUrl({
      phoneNumber,
      email,
      idNumber,
      sendUsingAllMethods,
    });

    otpData.sendOtpRequestStatus = requestStatus.SENDING;
    try {
      otpData.resendOtpTimeout = resendOtpTimeoutInSeconds;
      updateResendOtpTimeout();
      const response = await apiCall({
        url,
        data: requestData,
        method: "POST",
      });
      otpData.sendOtpRequestStatus = requestStatus.COMPLETE;
      otpData.otpSent = true;
    } catch (error) {
      otpData.sendOtpRequestStatus = requestStatus.ERROR;
    }
  }

  onMounted(() => {
    if (sendOtpImmediately) {
      resetOtpToSendStage();
    }
  });

  return { otpData, sendOtp, resetOtpToSendStage };
}

export function useValidateOtp() {
  const validateOtpRequestStatus = ref(requestStatus.NOT_SENT);
  const validatingOtp = computed(() => {
    return validateOtpRequestStatus.value === requestStatus.SENDING;
  });
  const otpInvalid = computed(() => {
    return validateOtpRequestStatus.value === INVALID_OTP;
  });
  async function validateOtp({ otp, otpIdentifier }) {
    const requestData = { otp, id_number: otpIdentifier };
    try {
      validateOtpRequestStatus.value = requestStatus.SENDING;
      const response = await apiCall({
        url: "/api/client/validate-otp",
        data: requestData,
        method: "POST",
      });
      const token = response.token;
      validateOtpRequestStatus.value = requestStatus.COMPLETE;
      return token;
    } catch (error) {
      validateOtpRequestStatus.value = requestStatus.ERROR;
      const responseError = error.response;
      const errorCode = responseError.status;
      const errorText = /*responseError.data.error*/ responseError.data.message;
      const otpInvalid = isOtpInvalid({ errorCode, errorText });
      if (otpInvalid) {
        validateOtpRequestStatus.value = INVALID_OTP;
      }
      throw error;
    }
  }
  function resetOtpStatusToInitial() {
    validateOtpRequestStatus.value = requestStatus.NOT_SENT;
  }

  return {
    validatingOtp,
    validateOtpRequestStatus,
    validateOtp,
    otpInvalid,
    resetOtpStatusToInitial,
  };
}

export function useOtpStatus() {
  const currentOtpStatus = ref(otpStatus.NOT_VALIDATED);
  const otpInvalid = computed(() => {
    return currentOtpStatus.value === otpStatus.OTP_INVALID;
  });
  function setOtpStatus(status) {
    currentOtpStatus.value = status;
  }
  function resetOtpStatusToInitial() {
    currentOtpStatus.value = otpStatus.NOT_VALIDATED;
  }
  return {
    currentOtpStatus,
    setOtpStatus,
    otpInvalid,
    resetOtpStatusToInitial,
  };
}
